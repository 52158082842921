/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap'); */
@import url('../font/style.css');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('../font/style.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }

  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground leading-[normal] m-0;
  }
  .scroll-container {
    scrollbar-width: thin;
    scrollbar-color: #1d2a51 #151c30; /* sivarbet-border and sivarbet-background colors */
  }

  .scroll-container::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .scroll-container::-webkit-scrollbar-thumb {
    background-color: #1d2a51; /* sivarbet-border color */
    border-radius: 3px;
  }

  .scroll-container::-webkit-scrollbar-track {
    background: #151c30; /* sivarbet-background color */
  }
}

* {
  @apply border-border;
  box-sizing: border-box;
}

body {
  @apply bg-background text-foreground font-sans;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  font-family: 'DM Sans', sans-serif;
}

body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: #1d2a51; /* sivarbet-border color */
  border-radius: 3px;
}

body::-webkit-scrollbar-track {
  background: #151c30; /* sivarbet-background color */
}

body {
  overflow-x: hidden;
}

*, ::before, ::after {
  border-width: 0;
}

.react-tel-input .country-list, 
.react-tel-input .country-list .country.highlight,
.react-tel-input .country-list .country:hover {
  background-color: #0c0f1a !important;
}
.react-tel-input .country-list .country .dial-code {
  color: #fff !important;
}
.react-tel-input .form-control {
  color: #fff !important;
}
.react-tel-input .flag-dropdown.open .selected-flag,
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus{
  background-color: transparent !important;
}
input:-webkit-autofill {  
  background-color: #0A0E1A !important;
  -webkit-box-shadow: 0 0 0px 1000px #0A0E1A inset !important;
  -webkit-text-fill-color: #ffffff !important;
}
.inputNoEditable {  
  border: none !important;
  -webkit-border:none !important;
}


.input-form-text {
  @apply bg-gray ring-offset-sivarbet-border border-none
}

.input-phone-button {
  @apply bg-gray border-l-0 border-r-2 border-sivarbet-border border-t-0 border-b-0 !important;
}

.input-phone-button:hover {
  @apply bg-sivarbet-primary-hover !important;
}

.button-primary-full {
  @apply w-full cursor-pointer [border:none] py-[15px] px-5 bg-sivarbet-primary self-stretch rounded-lg overflow-hidden flex flex-row items-center justify-center
}

@keyframes back {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

@layer components {
  .btn-banner {
    @apply mt-4 bg-sivarbet-primary text-sivarbet-border text-xs max-sm:text-sm md:text-base font-medium hover:text-sivarbet-text max-sm:mt-1;
  }
  .carousel-button {
    @apply static rounded-lg border-sivarbet-border flex items-center ;
    transform: none;
    background-color: transparent;
  }
  .text-primary {
    @apply text-sivarbet-text font-medium;
  }
  .select-trigger {
    @apply border-none outline-none !bg-[#0A0E1A] rounded-lg p-3.5 box-border text-sm text-sivarbet-secondary-text focus:border-white focus:ring-0;
    height: 2.375rem; /* Convertido desde h-9.5 */
    /*color: #yourColorCode; /* Reemplaza con el color deseado para text-secondary_text */
}

.select-trigger:focus {
    border-color: white; /* Equivalente a focus:border-white */
}


  .flex-col-gap-10 {
    @apply flex flex-col gap-[10px];
  }

  .full-width-primary-button {
    @apply w-full bg-sivarbet-primary rounded-lg p-3.5 box-border text-sm font-medium;
    height: 2.375rem; /* Convertido desde h-9.5 */
    color: white; /* Reemplazo de text-white */
}


.centered-full {
  @apply flex items-center justify-center w-full h-full text-xl font-medium;
  color: inherit; /* Reemplazo de text-inherit */
}

  .flex-col-start-gap-2 {
    @apply flex-1 flex flex-col items-start justify-start gap-2;
  }
  .rounded-full-primary {
    @apply w-9 h-9 rounded-full flex items-center justify-center;
    /*background-color: #yourPrimaryColor; /* Reemplazo de bg-primary_t */
}

.relative-full-secondary-text {
  @apply w-full relative leading-5 font-medium;
  /*color: #yourSecondaryTextColor; /* Reemplazo de text-secondary_text */
}

.card-secondary {
  @apply min-w-[280px] w-80 rounded-lg flex flex-row items-center justify-start p-8 box-border shrink-0;
  /*background-color: #yourSecondaryBgColor; /* Reemplazo de bg-secondary_bg */
}

.button-primary {
  @apply md:hidden w-full rounded-lg bg-sivarbet-primary overflow-hidden flex flex-row items-center justify-center py-3 px-5 text-center text-sivarbet-border;
  /*color: #yourPrimaryBorderColor; /* Reemplazo de text-primary_border */
}

  .flex-responsive-gap-2 {
    @apply flex flex-col md:flex-row gap-2 mt-2 md:mt-0 items-start md:items-end;
  }
  .flex-center-gap-2 {
    @apply flex items-center gap-2;
  }
  .divider {
    @apply w-full h-px box-border border-t border-sivarbet-border;
  }
  .relative-inline-text {
    @apply relative text-sm leading-3 font-sans text-sivarbet-border text-center inline-block min-w-16;
    /*color: #yourPrimaryBorderColor; /* Reemplazo de text-primary_border */
}

.small-rounded-border {
  @apply h-4 w-4 rounded;
  border: 1px solid #d1d5db; /* Reemplazo de border-gray-300 */
}

  .flex-col-row-between {
    @apply w-full flex flex-col md:flex-row mb-2 justify-between items-start md:items-center;
  }
  .popover-trigger {
    @apply border-none outline-none bg-[#0A0E1A] rounded-lg p-3.5 box-border text-sm focus:ring-0;
    height: 2.375rem; /* Reemplazo de h-9.5 */
    /*color: #yourSecondaryTextColor; /* Reemplazo de text-secondary_text */
}

.popover-trigger:focus {
    border-color: white; /* Reemplazo de focus:border-white */
}

  .flex-col-start-grow {
    @apply flex flex-col items-start flex-grow;
  }
  .text-secondary-small {
    @apply text-sivarbet-secondary-text text-[12px];
  }
  .flex-row-wrap-gap-10 {
    @apply flex flex-row flex-wrap items-center gap-[10px] w-full;
  }
  .flex-col-start {
    @apply flex flex-col items-start;
  }
  .text-dm-sans {
    @apply text-sm font-medium leading-5;
    font-family: 'DM Sans', sans-serif; /* Reemplazo de font-dm-sans */
}

  .info-header {
    @apply text-sivarbet-text font-medium text-lg mb-4 max-md:mb-2;
  }
  .list-secondary-gap-2 {
    @apply text-sivarbet-secondary-text flex flex-col gap-2;
  }
  .hover-primary {
    @apply w-full py-6 font-medium hover:bg-sivarbet-primary hover:text-sivarbet-border;
    background-color: transparent; /* Reemplazo de bg-transparent */
}

  .flex-col-start-box {
    @apply flex-1 flex flex-col items-start justify-start pt-[5.5px] px-0 pb-0 box-border min-w-[74px];
  }
  .divider-primary {
    @apply self-stretch h-px relative box-border border-t-[1px] border-solid;
    /* border-top-color: #yourPrimaryBorderColor; /* Reemplazo de border-primary_border */
}

  .center-box {
    @apply w-[145px] flex items-center justify-center pt-[4.5px] px-0 pb-0 box-border;
  }
  .stretch-text-center {
    @apply self-stretch h-2.5 relative text-sm leading-[20px] font-medium text-sivarbet-primary text-center flex items-center justify-center;
  }
  .btn-toggle {
    @apply cursor-pointer [border:none] py-[10.5px] px-5 bg-sivarbet-background self-stretch rounded-lg overflow-hidden flex flex-row items-center justify-center gap-[8px];
  }
  .link-primary {
    @apply hover:text-sivarbet-primary flex flex-col items-center gap-2 text-sm;
  }
  .flex-center-active {
    @apply flex items-center justify-center m-1 p-2 rounded-xl data-[state=active]:bg-sivarbet-primary;
  }
  .icon-common {
    display: inline-block;
    width: 1em;
    height: 1em;
}

  .dropdown-item {
    @apply flex items-center m-1 focus:!bg-sivarbet-secondary-bg hover:!bg-sivarbet-secondary-bg !cursor-pointer;
  }
  .flex-center-full {
    @apply flex items-center justify-center p-2 rounded-xl w-full data-[state=active]:bg-sivarbet-primary;
  }
  .flex-col-bg {
    @apply flex flex-col bg-sivarbet-background my-5 rounded-xl gap-4 py-2;
  }
  .text-lg-medium {
    @apply px-4 py-3 text-lg font-medium;
  }
  .popover-content {
    @apply bg-sivarbet-background border-sivarbet-border text-sivarbet-text w-full rounded-t rounded-xl shadow-2xl shadow-gray backdrop-opacity-30;
  }
  .absolute-center {
    @apply absolute left-2 flex h-3.5 w-3.5 items-center justify-center;
  }
  .absolute-top-right {
    @apply absolute top-2 right-2;
    color: white; /* Reemplazo de text-white */
}

  .shadow-justify-rounded-bg {
    @apply shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] justify-start text-left rounded-lg bg-[#0A0E1A] p-3.5 font-medium min-w-[113px];
  }
  .flex-col-large {
    @apply w-[886px] h-auto py-5 flex flex-col gap-5 rounded-lg;
  }
  .responsive-card {
    @apply md:w-full w-[353px] bg-[#101422] p-8 flex flex-col gap-3 min-h-[220px] max-w-full text-left text-sm text-sivarbet-text rounded-lg;
    font-size: 0.875rem; /* Equivalente a text-sm */
    font-weight: 500; /* Reemplazo de font-text-sm-medium */
}

  .responsive-small-card {
    @apply w-full md:w-1/3 bg-[#101422] p-4 rounded-lg text-sm text-sivarbet-text;
    font-size: 0.875rem; /* Equivalente a text-sm */
    font-weight: 500; /* Reemplazo de font-text-sm-medium */
}

  .hidden-title-md {
    @apply hidden md:block w-full h-[14px] text-lg font-medium leading-5 text-white;
    color: white; /* Reemplazo de text-white */
}

  .input-style {
    @apply w-full border-none outline-none bg-[#0A0E1A] rounded-lg p-3.5 box-border text-sm text-sivarbet-secondary-text focus:border-white focus:ring-0;
    /*color: #yourSecondaryTextColor; /* Reemplazo de text-secondary_text */
}

.input-style:focus {
    border-color: white; /* Reemplazo de focus:border-white */
}

  .overlay-center {
    @apply fixed inset-0 flex items-center justify-center z-50;
    background-color: rgba(0, 0, 0, 0.5); /* Reemplazo de bg-black bg-opacity-50 */
}

  .card-md {
    @apply bg-[#0A0E1A] p-6 rounded-lg w-full max-w-md;
  }
  .section-style {
    @apply mt-4 w-[360px] md:w-full flex flex-col items-start justify-start gap-3 text-left text-lg font-sans;
    /*color: #yourPrimaryTextColor; /* Reemplazo de text-primary_text */
}

  .divider-full-width {
    @apply w-full h-px relative box-border border-t border-sivarbet-border;
  }
}






#game_wrapper {
  width: 100%;
  height: 100%;
  background-color: #1a1a20;
}

#game_wrapper iframe {
  width: 100%;
  height: 100%;
  background-color: #1a1a20;
  border: none;
}



.react-datepicker{
  border: 0 !important;
  background: #1d2a51 !important;
  margin-top: 20px;
}
.react-datepicker__header {
  background: #1d2a51 !important;
  color: #fff !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name, 
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  color: #fff !important;
}
.react-datepicker-wrapper{
  width: 100% !important;
}
  

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #0A0E1A;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #95FB3F; /* Color del thumb */
  border-radius: 10px;
  border: 2px solid #0A0E1A;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #46FC6D;
}
.clipR{
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 14% 48%, 0% 0%);
}
.clipL{
  clip-path: polygon(100% 0%, 86% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
}

.mask{
  mask-image: linear-gradient( to bottom, #1D2A51 60%, transparent );

}

.bgAgent{
  background-image: url('/img/background/BgMobile-marketing.png');
  background-size:cover;
  background-repeat: no-repeat;
  background-attachment: local;
}
.animationPulse{
  animation: pulse 2s ease-in-out infinite 1s; 
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

@media (min-width: 768px) {
  .bgAgent{
    background-image: url('/img/background/Bgweb-marketing.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.tournament-background {
  position: relative;
}

.tournament-background::before {
  content: "";
  background-image: url('/img/background/torneobackground.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@media (max-width: 768px) {
  .tournament-background::before {
    background-size: cover;
  }
}

.bgTournamentsA{
  background-image: url('/img/background/tournaments-1.webp');
  background-repeat: no-repeat;
  background-size: cover;
  height: 111px;
  width: 211px;
}

.bgTournamentsC {
  background-image: url('/img/background/tournaments-3.webp');
  background-repeat: no-repeat;
  background-size: cover;
  height: 130px;
  width: 130px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin-on-load {
  animation: spin 5s linear ;
}

.hover\:animate-spin:hover {
  animation: spin 2s linear infinite;
}

.primary-bg-btn {
  background-image: radial-gradient(circle, rgb(149, 251, 63) 60%, rgb(70, 252, 109) 100%);
  box-shadow: 0 0 12px #67f86e85, 0 -2px #82fc3c inset;
  position: relative;
  overflow: hidden;
}

.primary-bg-btn::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(45deg);
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.primary-bg-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 20px #46fd6e, 0 -2px #70ff4c inset;
}

.primary-bg-btn:hover::before {
  animation: shine 1s infinite;
  opacity: 1;
}

@keyframes shine {
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) rotate(45deg);
  }
}
.confetti-triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #fff;
  position: relative;
  transform: rotate(35deg);
}

.confetti-semicircle {
  border-radius: 50% 50% 0 0;
  transform: rotate(180deg);
}

.confetti-circle {
  border-radius: 50%;
}

.confetti-square {
  border-radius: 0;
}

